export const FirebaseConfig = {
	"projectId": "donkey-drive-5cdd1",
	"appId": "1:919890316898:web:c44b21e866945658598b3b",
	"databaseURL": "https://donkey-drive-5cdd1-default-rtdb.firebaseio.com",
	"storageBucket": "donkey-drive-5cdd1.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAtAc3Q1xCjfE1QBIIvj7Qzs_fweR7jwmw",
	"authDomain": "donkey-drive-5cdd1.firebaseapp.com",
	"messagingSenderId": "919890316898",
	"measurementId": "G-FQG2V6QZE0"
};